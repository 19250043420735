<template>
  <div>
    <h1> {{translationKeywords['page-not-found'] || 'The website is under construction'}}</h1>
  </div>
</template>

<script>

import { giturlLangCode } from "@/helpers/url";
import { getTranslationKeywords } from "@/store/api";

export default {
  name:"page-not-found",
  data: () => ({
    translationKeywords:{}
  }),
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords=keywords)
    );
  }
}
</script>

<style>

</style>